import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

if ($(window).width() <= 992) {
    const heroSliderClass = '.js-benefits-icons-slider';

    // Swiper init
    const heroSlider = new Swiper(heroSliderClass, {
        modules: [Autoplay],
        slidesPerView: 2.2,
        loop: true,
        spaceBetween: 16,
        autoplay: {
            delay: 2000,
        },
    });
}
